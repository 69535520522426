import React from "react";
import { Modal } from "components/UI/Modal";
import { Title } from "components/UI/Title";
import Text from "components/UI/Text";
import { Button } from "components/UI/Button";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { selectVacancyData } from "services/store/modules/vacancy/selectors";
import { decrementPeopleCountOpen } from "../../services/store/modules/vacancy";

type modeType = "candidate" | "vacancy";

interface CandidateModalsProps {
  isOpen: boolean;
  onClose: () => void;
  mode: modeType;
}

export const CandidateModals: React.FC<CandidateModalsProps> = ({
  isOpen,
  onClose,
  mode,
}) => {
  const dispatch = useAppDispatch();
  const vacancy = useAppSelector(selectVacancyData);
  const history = useHistory();

  const peopleCountOpen = vacancy?.peopleCountOpen ?? 0;
  const lastPosition = peopleCountOpen - 1 === 0;

  const titleMessage = lastPosition
    ? "Вы набрали необходимое количество кандидатов по данной вакансии"
    : `Потребность в количестве ${peopleCountOpen} уменьшена`;

  const textMessage = lastPosition
    ? "Перейдите к другим вакансиям"
    : "Вы будете переведены к списку вакансии";

  const handleOkClick = () => {
    if (mode === "candidate") {
      return lastPosition ? history.push("/vacancies-view") : history.goBack();
    }
    dispatch(decrementPeopleCountOpen());
    onClose();
    if (lastPosition) {
      history.push("/vacancies-view");
    }
  };

  return (
    <Modal open={isOpen} footer={false} onCancel={onClose}>
      <div className="candidates-actions-modal">
        <Title type="h2">{titleMessage}</Title>
        <Text text={textMessage} className="candidates-actions-modal-text" />
        <Button
          className="candidates-actions-modal-btn"
          onClick={handleOkClick}
        >
          OK
        </Button>
      </div>
    </Modal>
  );
};
