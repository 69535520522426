import moment, { Moment } from "moment/min/moment-with-locales";

type Format =
  | "DD.MM.YYYY"
  | "YY-MM-DD"
  | "DD.MM.YY"
  | "YYYY-MM-DD"
  | "DD.MM.YY HH:mm"
  | "D MMMM YYYY";

export const getDateFormat = (
  date: string | Date | Moment,
  format: Format,
  locale = "ru"
) => {
  return moment(date).locale(locale).format(format);
};
