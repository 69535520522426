import { getTrueName } from "common/helpers/string";
import { getDateFormat } from "common/utils/date";
import { EmploymentType, VacancyType } from "graphql/types/types";
import { getEmploymentById } from "services/store/modules/directory/getters";

const EMPTY_INFO = "Не указано";

export const getVacancyInfoLeftColumn = ({
  address,
  shortDescription,
  callCenterInfo,
}: VacancyType) => [
  {
    title: "Торговая точка",
    type: "h3" as const,
    color: "grey" as const,
    content: callCenterInfo.project ?? EMPTY_INFO,
  },
  {
    title: "Описание вакансии",
    type: "h3" as const,
    color: "grey" as const,
    html: shortDescription ?? EMPTY_INFO,
  },
  {
    title: "Адрес",
    type: "h3" as const,
    color: "grey" as const,
    content: address.value ?? EMPTY_INFO,
  },
];

export const getVacancyInfoRightColumn = (
  {
    age,
    description,
    salary,
    callCenterInfo,
    claims,
    terms,
    shiftStart,
    shiftEnd,
    employment,
    citizenship,
    medicalBook,
    schedule,
  }: VacancyType,
  employments: EmploymentType[]
) => [
  {
    title: "Обязанности",
    type: "h3" as const,
    color: "grey" as const,
    html: description ?? EMPTY_INFO,
  },
  {
    title: "Заработная плата",
    type: "h3" as const,
    color: "grey" as const,
    isTitle: true,
    content:
      salary.from && salary.to
        ? `${salary.from} ₽ - ${salary.to} ₽`
        : EMPTY_INFO,
  },
  {
    title: "Требования",
    type: "h3" as const,
    color: "grey" as const,
    html: claims ?? EMPTY_INFO,
  },
  {
    title: "Условия",
    type: "h3" as const,
    color: "grey" as const,
    html: terms ?? EMPTY_INFO,
  },
  {
    title: "Описание вакансии для КЦ (информацию кандидату не озвучиваем!)",
    type: "h3" as const,
    color: "grey" as const,
  },
  {
    title: "Краткое описание:",
    type: "h3" as const,
    color: "grey" as const,
    html: callCenterInfo.description ?? EMPTY_INFO,
  },
  {
    title: "Адреса мест работ:",
    type: "h3" as const,
    color: "grey" as const,
    html: callCenterInfo.addresses ?? EMPTY_INFO,
  },
  {
    title: "График работы:",
    type: "h3" as const,
    color: "grey" as const,
    html: schedule ?? EMPTY_INFO,
  },
  {
    title: "Начало и окончание смены:",
    type: "h3" as const,
    color: "grey" as const,
    isShort: true,
    content:
      shiftStart && shiftEnd
        ? `${getDateFormat(shiftStart, "DD.MM.YY HH:mm")} - ${getDateFormat(
            shiftEnd,
            "DD.MM.YY HH:mm"
          )}`
        : EMPTY_INFO,
  },
  {
    title: "Тип занятости:",
    type: "h3" as const,
    color: "grey" as const,
    isShort: true,
    content: employment?.id
      ? getEmploymentById(employments, employment.id)?.name || EMPTY_INFO
      : EMPTY_INFO,
  },
  {
    title: "Гражданство:",
    type: "h3" as const,
    color: "grey" as const,
    isShort: true,
    content: citizenship?.name || EMPTY_INFO,
  },
  {
    title: "Возраст (от/до):",
    type: "h3" as const,
    color: "grey" as const,
    isShort: true,
    content: age.from && age.to ? `${age.from} - ${age.to}` : EMPTY_INFO,
  },
  {
    title: "Вод. права (категории):",
    type: "h3" as const,
    color: "grey" as const,
    isShort: true,
    content: callCenterInfo.driverLicense?.length
      ? callCenterInfo.driverLicense.join(", ")
      : EMPTY_INFO,
  },
  {
    title: "Личный авто:",
    type: "h3" as const,
    color: "grey" as const,
    isShort: true,
    content: getTrueName(Boolean(callCenterInfo.personalCar)),
  },
  {
    title: "Наличие КПК:",
    type: "h3" as const,
    color: "grey" as const,
    isShort: true,
    content: callCenterInfo.presenceOfPda || EMPTY_INFO,
  },
  {
    title: "Мед.книжка:",
    type: "h3" as const,
    color: "grey" as const,
    isShort: true,
    content: getTrueName(Boolean(medicalBook)),
  },
  {
    title: "Вакцинация:",
    type: "h3" as const,
    color: "grey" as const,
    isShort: true,
    content: callCenterInfo.vaccination || EMPTY_INFO,
  },
  {
    title: "Опыт работы:",
    type: "h3" as const,
    color: "grey" as const,
    isShort: true,
    content: callCenterInfo.experience || EMPTY_INFO,
  },
  {
    title: "Оплата проезда:",
    type: "h3" as const,
    color: "grey" as const,
    isShort: true,
    content: callCenterInfo.farePayment || EMPTY_INFO,
  },
  {
    title: "Мобильная связь (оплата):",
    type: "h3" as const,
    color: "grey" as const,
    isShort: true,
    content: getTrueName(Boolean(callCenterInfo.mobilePayment)),
  },
  {
    title: "Категория товара:",
    type: "h3" as const,
    color: "grey" as const,
    isShort: true,
    content: callCenterInfo.goodsCategory || EMPTY_INFO,
  },
];
