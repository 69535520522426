export default function getCookie(name: string) {
  let matches = document.cookie.match(
    new RegExp(
      /* eslint-disable */
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const initializeDomainLinker = () => {
  const host = window.location.hostname;
  const cookieDomain = "auto";
  const cookieFlags = "secure; samesite=none"; // Необходимо для работы на HTTPS
  const cookieConfig = `domains=${host}; cookieflags=${cookieFlags}; cookieSecure=${
    cookieFlags.includes("secure") ? "true" : "false"
  }`;

  document.cookie = `_gl=${window.dataLayer} ${cookieConfig}; domain=${cookieDomain}; path=/`;
  document.cookie = `_gl=${window.dataLayer} ${cookieConfig}; domain=.${cookieDomain}; path=/`;
};
